import { graphql } from 'gatsby';
import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import HomeAnalytics from 'core/framework/analytic/Home';
import { isProduction } from 'services/config';

import FirstScreen from 'components/index/FirstScreen';
import SecondScreen from 'components/index/SecondScreen';
import ThirdScreen from 'components/index/ThirdScreen';
import FourthScreen from 'components/index/FourthScreen';
import PickBar from 'components/shared/pick-bar/PickBar';

import Layout from 'components/layouts/main/Layout';

interface Props {
  path: string;
  data: {
    [imageName: string]: {
      childImageSharp: {
        fluid: string;
      };
    };
  };
}

const IndexPage: React.FC<Props> = ({ data, path }: Props) => {
  const [pickUpIsVisible, setPickUpIsVisible] = useState(false);
  const [dispensariesIsVisible, setDispensariesIsVisible] = useState(false);
  const dispensariesBlockRef = React.createRef();

  HomeAnalytics.homePage();

  return (
    <Layout
      location={path}
      setNavAbsolute={dispensariesIsVisible || pickUpIsVisible}
      dispensariesBlockRef={dispensariesBlockRef}
    >
      <FirstScreen backgroundImage={data.bgImgFirstScreen.childImageSharp.fluid} />

      <SecondScreen firstSlideImage={data.firstSlideImage.childImageSharp.fluid} />

      <ThirdScreen
        restoreTheme={data.restoreTheme.childImageSharp.fluid}
        goTheme={data.goTheme.childImageSharp.fluid}
        chillTheme={data.chillTheme.childImageSharp.fluid}
        dreamTheme={data.dreamTheme.childImageSharp.fluid}
        restoreProduct={data.restoreProduct.childImageSharp.fluid}
        goProduct={data.goProduct.childImageSharp.fluid}
        chillProduct={data.chillProduct.childImageSharp.fluid}
        dreamProduct={data.dreamProduct.childImageSharp.fluid}
      />

      <FourthScreen
        firstImg={data.firstImg.childImageSharp.fluid}
        secondImg={data.secondImg.childImageSharp.fluid}
        thirdImg={data.thirdImg.childImageSharp.fluid}
        fourthImg={data.fourthImg.childImageSharp.fluid}
        fifthImg={data.fifthImg.childImageSharp.fluid}
        sixthImg={data.sixthImg.childImageSharp.fluid}
        seventhImg={data.seventhImg.childImageSharp.fluid}
        setDispensariesIsVisible={setDispensariesIsVisible}
        dispensariesBlockRef={dispensariesBlockRef}
      />

      <VisibilitySensor
        partialVisibility
        scrollCheck
        scrollThrottle={10}
        onChange={(isVisible) => setPickUpIsVisible(isVisible)}
      >
        <PickBar currentPage="home" />
      </VisibilitySensor>

      {isProduction ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img alt="Traffic Roots Conversion Pixel" src="//service.trafficroots.com/conversion/8c8443ce03?click_id=your_click_id&amount=conversion_amount" style="display:none;">`,
          }}
        />
      ) : null}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    bgImgFirstScreen: file(relativePath: { eq: "bg-chill.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firstSlideImage: file(relativePath: { eq: "home-slide-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    restoreTheme: file(relativePath: { eq: "CLICK_Restore-Image-01_x1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goTheme: file(relativePath: { eq: "CLICK_Go-Image-02_x1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chillTheme: file(relativePath: { eq: "CLICK_Chill-Image-01_x1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dreamTheme: file(relativePath: { eq: "CLICK_Dream-Image-01_x1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    restoreProduct: file(relativePath: { eq: "restore-product-image.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goProduct: file(relativePath: { eq: "go-product-image.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chillProduct: file(relativePath: { eq: "chill-product-image.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dreamProduct: file(relativePath: { eq: "dream-product-image.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firstImg: file(relativePath: { eq: "insta-img-one.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(relativePath: { eq: "insta-img-two.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thirdImg: file(relativePath: { eq: "insta-img-three.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fourthImg: file(relativePath: { eq: "insta-img-four.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fifthImg: file(relativePath: { eq: "insta-img-five.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sixthImg: file(relativePath: { eq: "insta-img-six.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seventhImg: file(relativePath: { eq: "insta-img-seven.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
