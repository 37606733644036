import React, { Component, ReactNode } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './ThirdScreen.module.scss';
import logo from '../../images/Logo-Mark-White.svg';

interface Props {
  restoreTheme: string;
  goTheme: string;
  chillTheme: string;
  dreamTheme: string;
  restoreProduct: {
    src: string;
  };
  goProduct: {
    src: string;
  };
  chillProduct: {
    src: string;
  };
  dreamProduct: {
    src: string;
  };
}

interface ScreenStyles {
  background?: string;
  triangleImage?: string;
  triangleBg?: string;
  triangleImageStyles?: {
    height?: string;
    width?: string;
    objectPosition?: string;
  };
  triangleImageMobileStyles?: {
    height?: string;
    width?: string;
    objectPosition?: string;
  };
}
type Returned = ScreenStyles;

export default class ThirdScreen extends Component<Props> {
  state: {
    activeProduct: string;
    animationTrigerred: boolean;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeProduct: 'go',
      animationTrigerred: false,
    };

    this.productHovered = this.productHovered.bind(this);
  }

  productHovered(product: string): void {
    this.setState({ activeProduct: product });
  }

  get screenStyles(): Returned {
    if (this.state.activeProduct === 'restore') {
      return {
        background: '#6ADF8B',
        triangleImage: this.props.restoreTheme,
        triangleBg: '#66DDBA',
        triangleImageStyles: {
          height: '100%',
          width: '120%',
          objectPosition: '0 bottom',
        },
        triangleImageMobileStyles: {
          height: '100%',
          width: '100%',
          objectPosition: '20% bottom',
        },
      };
    } else if (this.state.activeProduct === 'go') {
      return {
        background: '#FF3800',
        triangleImage: this.props.goTheme,
        triangleBg: '#F78465',
        triangleImageStyles: {
          height: '115%',
          width: '115%',
          objectPosition: '18% 12vw',
        },
        triangleImageMobileStyles: {
          height: '100%',
          width: '100%',
          objectPosition: '25% bottom',
        },
      };
    } else if (this.state.activeProduct === 'chill') {
      return {
        background: '#5E91FF',
        triangleImage: this.props.chillTheme,
        triangleBg: '#B7ECFE',
        triangleImageStyles: {
          height: '100%',
          width: '100%',
          objectPosition: '40% bottom',
        },
        triangleImageMobileStyles: {
          height: '100%',
          width: '100%',
          objectPosition: '60% bottom',
        },
      };
    } else if (this.state.activeProduct === 'dream') {
      return {
        background: '#000059',
        triangleImage: this.props.dreamTheme,
        triangleBg: '#3F3AFF',
        triangleImageStyles: {
          height: '100%',
          width: '100%',
          objectPosition: '0 5vw',
        },
        triangleImageMobileStyles: {
          height: '90%',
          width: '100%',
          objectPosition: '45% bottom',
        },
      };
    }
    return {};
  }

  render(): ReactNode {
    return (
      <section className={styles.screenBox} style={{ background: this.screenStyles.background }}>
        <div
          className={styles.pictureTriangle}
          style={{ background: this.screenStyles.triangleBg }}
        >
          <Img
            style={{
              height: this.screenStyles?.triangleImageStyles?.height,
              width: this.screenStyles?.triangleImageStyles?.width,
            }}
            className={styles.backgroundImageDesktop}
            objectFit="cover"
            objectPosition={this.screenStyles?.triangleImageStyles?.objectPosition}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            fluid={this.screenStyles.triangleImage}
            fadeIn={false}
            loading="eager"
          ></Img>
          <Img
            style={{
              height: this.screenStyles?.triangleImageMobileStyles?.height,
              width: this.screenStyles?.triangleImageMobileStyles?.width,
            }}
            className={styles.backgroundImageMobile}
            objectFit="cover"
            objectPosition={this.screenStyles?.triangleImageMobileStyles?.objectPosition}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            fluid={this.screenStyles.triangleImage}
            fadeIn={false}
            loading="eager"
          ></Img>
        </div>
        <div className={styles.topText}>
          <img src={logo} alt="Logo"></img>
          <div className={styles.textBox}>
            <h1>CLICK into your life</h1>
          </div>
        </div>
        <VisibilitySensor
          partialVisibility
          offset={{ top: 700 }}
          active={!this.state.animationTrigerred}
          scrollCheck
          scrollThrottle={10}
          onChange={(isVisible) => {
            if (isVisible && !this.state.animationTrigerred) {
              this.setState({ animationTrigerred: true });
            }
          }}
        >
          {({ isVisible }) => (
            <div className={styles.productsBox}>
              <ProductName
                activeProduct={this.state.activeProduct}
                isVisible={isVisible}
                name="go"
                productHovered={(name) => this.productHovered(name)}
              />
              <ProductName
                activeProduct={this.state.activeProduct}
                isVisible={isVisible}
                name="restore"
                productHovered={(name) => this.productHovered(name)}
              />
              <ProductName
                activeProduct={this.state.activeProduct}
                isVisible={isVisible}
                name="chill"
                productHovered={(name) => this.productHovered(name)}
              />
              <ProductName
                activeProduct={this.state.activeProduct}
                isVisible={isVisible}
                name="dream"
                productHovered={(name) => this.productHovered(name)}
              />
            </div>
          )}
        </VisibilitySensor>
        <img
          style={{
            zIndex: this.state.activeProduct === 'restore' ? 1 : -1,
          }}
          src={this.props.restoreProduct?.src}
          alt="restore product"
          className={styles.productImage}
        ></img>
        <img
          style={{ zIndex: this.state.activeProduct === 'go' ? 1 : -1 }}
          src={this.props.goProduct.src}
          alt="go product"
          className={styles.productImage}
        ></img>
        <img
          style={{ zIndex: this.state.activeProduct === 'chill' ? 1 : -1 }}
          src={this.props.chillProduct.src}
          alt="chill product"
          className={styles.productImage}
        ></img>
        <img
          style={{ zIndex: this.state.activeProduct === 'dream' ? 1 : -1 }}
          src={this.props.dreamProduct.src}
          alt="dream product"
          className={styles.productImage}
        ></img>
      </section>
    );
  }
}

interface ProductNameProps {
  name: string;
  activeProduct: string;
  isVisible: boolean;
  productHovered(arg: string): void;
}

class ProductName extends Component<ProductNameProps> {
  render() {
    const { name, activeProduct, isVisible } = this.props;

    return (
      <div className={styles.product + ' ' + (isVisible ? styles.animate : 'remove-transform')}>
        <Link to={`https://clickspray.com/${activeProduct}`}>
          <div
            onMouseEnter={() => {
              this.props.productHovered(name);
            }}
            className={
              styles.nameWrapper + ' ' + (activeProduct === name ? styles.activeProduct : '')
            }
          >
            <h2 data-hover={name}>{name}</h2>
          </div>
        </Link>
      </div>
    );
  }
}
