import ClickAnalytics from './Analytic';

class Analytics {
  public track(event: string): void {
    return ClickAnalytics.getInstance().track(event);
  }

  private page(page: string): void {
    return ClickAnalytics.getInstance().page(page);
  }

  public homePage() {
    this.page('home-page');
    this.track('Home Page');
  }

  public joinClick() {
    this.page('sign-up-started');
    this.track('Home Page: Join Our Click');
  }
}

export default new Analytics();
