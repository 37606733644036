import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './SecondScreen.module.scss';
import preciseIcon from '../../images/precise_blue.svg';
import healthyIcon from '../../images/healthy_blue.svg';
import easyIcon from '../../images/easy_blue.svg';

export default class SecondScreen extends React.Component<any> {
  state: any;
  slider: any;

  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0,
    };

    this.changeSlide = this.changeSlide.bind(this);
  }

  get activeSlideClass() {
    if (this.state.slideIndex === 0) {
      return styles.firstSlideActive;
    } else if (this.state.slideIndex === 1) {
      return styles.secondSlideActive;
    } else if (this.state.slideIndex === 2) {
      return styles.thirdSlideActive;
    }
    return '';
  }

  changeSlide(id) {
    this.setState({ slideIndex: id });
    this.slider.slickGoTo(id);
  }

  render() {
    const sliderSettings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 1000,
      autoplaySpeed: 8000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      autoplay: true,
      pauseOnHover: true,
      cssEase: 'linear',
      fade: true,
    };

    return (
      <section className={styles.screenBox}>
        <div className={styles.sliderBox}>
          <Slider
            ref={(slider) => (this.slider = slider)}
            {...sliderSettings}
            className={styles.slickSlider}
          >
            <Slide
              activeIndex={this.state.slideIndex}
              slideIndex={0}
              changeSlide={(id) => this.changeSlide(id)}
              firstSlideImage={this.props.firstSlideImage}
            />
            <Slide
              activeIndex={this.state.slideIndex}
              slideIndex={1}
              changeSlide={(id) => this.changeSlide(id)}
              firstSlideImage={this.props.firstSlideImage}
            />
            <Slide
              activeIndex={this.state.slideIndex}
              slideIndex={2}
              changeSlide={(id) => this.changeSlide(id)}
              firstSlideImage={this.props.firstSlideImage}
            />
          </Slider>
          <div className={styles.slidesControlWrapper}>
            <div className={styles.numbersWrapper}>
              <div
                className={styles.number}
                onClick={() => {
                  this.changeSlide(0);
                }}
              >
                01
              </div>
              <div
                className={styles.number}
                onClick={() => {
                  this.changeSlide(1);
                }}
              >
                02
              </div>
              <div
                className={styles.number}
                onClick={() => {
                  this.changeSlide(2);
                }}
              >
                03
              </div>
            </div>
            <div className={styles.sliderScrollControlBox}>
              <div className={styles.sliderThumb + ' ' + this.activeSlideClass}></div>
              <input
                className={styles.slidesControl}
                onChange={(e) => this.slider.slickGoTo(e.target.value)}
                value={this.state.slideIndex}
                type="range"
                min={0}
                max={2}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

class Slide extends Component<any> {
  render() {
    const { activeIndex, slideIndex, firstSlideImage } = this.props;

    return (
      <div className={(activeIndex === slideIndex ? styles.zIndexTwo : null) + ' ' + styles.slide}>
        <div className={styles.leftBox}>
          <div className={styles.bigText}>
            <h1>
              <span className={styles.strokedText}>
                CLICK IS<br></br>
              </span>
              {slideIndex === 0 ? <span>FAST ACTING</span> : null}
              {slideIndex === 1 ? <span>GREAT TASTING</span> : null}
              {slideIndex === 2 ? <span>PORTABLE</span> : null}
            </h1>
          </div>
          <div className={styles.circleControlsWrapper}>
            <div
              className={
                (slideIndex === 0 ? styles.activeControl : null) + ' ' + styles.circleControl
              }
              onClick={() => {
                this.props.changeSlide(0);
              }}
            >
              <img src={preciseIcon} alt="icon"></img>
            </div>
            <div
              className={
                (slideIndex === 1 ? styles.activeControl : null) + ' ' + styles.circleControl
              }
              onClick={() => {
                this.props.changeSlide(1);
              }}
            >
              <img src={healthyIcon} alt="icon"></img>
            </div>
            <div
              className={
                (slideIndex === 2 ? styles.activeControl : null) + ' ' + styles.circleControl
              }
              onClick={() => {
                this.props.changeSlide(2);
              }}
            >
              <img src={easyIcon} alt="icon"></img>
            </div>
          </div>
          {slideIndex === 0 ? (
            <p className={styles.smallText}>
              When sprayed under your tongue, CLICK will deliver exactly 2.5mg of cannabis every
              time. And you will feel the effect in as little as six to eight minutes.
            </p>
          ) : null}
          {slideIndex === 1 ? (
            <p className={styles.smallText}>
              With flavors like MoveMint, Lemon Ginger Bliss, TropiCali and Midnight Mint - there is
              a taste and experience for everyone.
            </p>
          ) : null}
          {slideIndex === 2 ? (
            <p className={styles.smallText}>
              As small as a pack of gum, CLICK is easy to take anywhere and fits in the palm of your
              hand. It&apos;s also waterproof and has a clip that hooks onto anything.
            </p>
          ) : null}
          <Link to="https://clickspray.com/chill" className={styles.learnMore + ' ' + styles.desktopLearnMore}>
            LEARN MORE
          </Link>
        </div>

        <div className={styles.rightBox}>
          <BackgroundImage
            Tag="div"
            fluid={firstSlideImage}
            style={{
              height: '100%',
              backgroundSize: 'contain',
              backgroundPositionY: 'top',
            }}
            fadeIn={false}
          ></BackgroundImage>
        </div>
      </div>
    );
  }
}
