import React, { Component } from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';

import styles from './PickBar.module.scss';
import BackgroundImage from 'gatsby-background-image';

interface Props {
  currentPage?: string;
  className?: classNameProps;
  title?: string;
}

interface classNameProps {
  container?: string;
  background?: string;
  title?: string;
}

const PickBar = ({ currentPage, className, title }: Props) => {
  const { restoreCard, goCard, chillCard, dreamCard } = useStaticQuery(
    graphql`
      query {
        restoreCard: file(relativePath: { eq: "restore.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        goCard: file(relativePath: { eq: "go.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chillCard: file(relativePath: { eq: "chill.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dreamCard: file(relativePath: { eq: "dream.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <Content
      currentPage={currentPage}
      className={className}
      title={title}
      restoreCard={restoreCard}
      goCard={goCard}
      chillCard={chillCard}
      dreamCard={dreamCard}
    ></Content>
  );
};
export default PickBar;

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animationTrigerred: false,
    };
  }

  render() {
    const { restoreCard, goCard, chillCard, dreamCard, currentPage, className, title } = this.props;

    return (
      <VisibilitySensor
        partialVisibility
        active={!this.state.animationTrigerred}
        scrollCheck
        scrollThrottle="10"
        onChange={(isVisible) => {
          if (isVisible && !this.state.animationTrigerred) {
            this.setState({ animationTrigerred: true });
          }
        }}
      >
        {({ isVisible }) => (
          <div
            className={classnames(
              styles.container + ' ' + (currentPage === 'about' ? styles.shadow : null),
              className?.container,
            )}
          >
            <div
              className={classnames(
                styles.background + ' ' + styles[`${currentPage}Background`],
                className?.background,
              )}
            ></div>
            <h1
              className={classnames(
                styles.title + ' ' + styles[`${currentPage}Title`],
                className?.title,
              )}
            >
              {title || 'CLICK INTO A FEELING'}
            </h1>

            <div className={styles.cardsBox}>
              <Card name="GO" image={goCard} productLink="https://clickspray.com/go/" isVisible={isVisible} />
              <Card
                name="RESTORE"
                image={restoreCard}
                productLink="https://clickspray.com/restore"
                isVisible={isVisible}
              />
              <Card
                name="CHILL"
                image={chillCard}
                productLink="https://clickspray.com/chill"
                isVisible={isVisible}
              />
              <Card
                name="DREAM"
                image={dreamCard}
                productLink="https://clickspray.com/dream"
                isVisible={isVisible}
              />
            </div>
          </div>
        )}
      </VisibilitySensor>
    );
  }
}

class Card extends Component {
  render() {
    const { name, image, productLink, isVisible } = this.props;

    return (
      <Link to={productLink} className={styles.card + ' ' + (isVisible ? styles.animate : '')}>
        <BackgroundImage
          Tag="div"
          fadeIn={false}
          loading="eager"
          className={styles.cardImg}
          fluid={image.childImageSharp.fluid}
        >
          <div className={styles.name}>{name}</div>
        </BackgroundImage>
      </Link>
    );
  }
}
