import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import DispensariesMap from '../shared/dispensaries-map/DispensariesMap';

import styles from './FourthScreen.module.scss';

interface Props {
  firstImg: FluidObject;
  secondImg: FluidObject;
  thirdImg: FluidObject;
  fourthImg: FluidObject;
  fifthImg: FluidObject;
  sixthImg: FluidObject;
  seventhImg: FluidObject;
  setDispensariesIsVisible: any;
  dispensariesBlockRef: any;
}

const FourthScreen: React.FC<Props> = ({
  fifthImg,
  firstImg,
  fourthImg,
  secondImg,
  seventhImg,
  sixthImg,
  thirdImg,
  setDispensariesIsVisible,
  dispensariesBlockRef,
}: Props) => (
  <section className={styles.screenBox}>
    <div className={styles.blueBackground} />

    <div className={styles.topText}>
      <h1>Join CLICK Rewards Today</h1>
      <h2>
        Get the latest product news, <br></br>redeem points, earn prizes
      </h2>
    </div>

    <div className={styles.instagramPhotos}>
      <Photo imgSrc={firstImg} />
      <Photo imgSrc={secondImg} />
      <Photo imgSrc={thirdImg} />
      <Photo imgSrc={fourthImg} />
      <Photo imgSrc={fifthImg} />
      <Photo imgSrc={sixthImg} />
      <Photo imgSrc={seventhImg} />
    </div>

    <Link to="/account/join" rel="noreferrer" target="_blank" className={styles.joinClick}>
      join our CLICK
    </Link>

    <VisibilitySensor
      partialVisibility
      scrollCheck
      scrollThrottle={10}
      onChange={(isVisible) => setDispensariesIsVisible(isVisible)}
    >
      <DispensariesMap setRef={dispensariesBlockRef} className={styles.dispensariesMap} />
    </VisibilitySensor>
  </section>
);

interface PhotoProps {
  imgSrc: FluidObject;
}

const Photo: React.FC<PhotoProps> = ({ imgSrc }: PhotoProps) => (
  <div className={styles.instagramPhoto}>
    <a
      href="https://www.instagram.com/clickspray/"
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={0}
    >
      <Img fadeIn={false} fluid={imgSrc} style={{ height: '100%' }} />
    </a>
  </div>
);

export default FourthScreen;
