import React from 'react';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import heroVideo from '../../videos/CLICK_HeroVideo.mp4';

import ButtonsBar from '../shared/buttons-bar/ButtonsBar';
// import ButtonToCart from '../shared/button-to-cart/ButtonToCart';

import styles from './FirstScreen.module.scss';
import { navigate } from 'gatsby';

interface IFirstScreenProps {
  backgroundImage: IFluidObject;
}

const FirstScreen = ({ backgroundImage }: IFirstScreenProps): JSX.Element => (
  <section className={styles.screenBox}>
    {/* <BackgroundImage
      Tag="div"
      className={styles.pictureTriangle}
      fluid={backgroundImage}
      fadeIn={false}
      loading="eager"
    /> */}

    <video src={heroVideo} autoPlay muted loop className={styles.heroVideo} />

    <div className={styles.blueTriangle} />

    <div className={styles.textWrapper}>
      <h1>
        CLICK: The<br></br>incredible, edible<br></br>cannabis spray
      </h1>
    </div>

    {/* <ButtonToCart className={styles.cartButton} onClick={() => navigate('/shop/delivery')} /> */}

    <ButtonsBar showText={false} />
  </section>
);

export default FirstScreen;
